<template>
  <div class="input-block">
    <div class="input-block__label" v-if="label" v-html="label"></div>
    <input
      type="text"
      :class="bem('input-block__input', { error: !!errorText })"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown="handleKeydown"
      ref="input"
    />
    <ErrorText v-if="errorText" class="input-block__error-text">{{
      errorText
    }}</ErrorText>
  </div>
</template>

<script>
import ErrorText from './ErrorText.vue'
export default {
  components: { ErrorText },
  name: 'InputBlock',
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    errorText: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleKeydown(event) {
      if (event.key === 'Enter') {
        this.$refs.input.blur()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.input-block {
  width: 100%;
  display: flex;
  flex-direction: column;

  &__label {
    font-size: 12rem;
    color: rgba(255, 255, 255, 0.3);
    font-weight: bold;
    margin-bottom: 0.4em;
    text-transform: uppercase;
  }

  &__input {
    padding: 16rem;
    font-size: 16rem;
    font-weight: bold;
    background-color: #504b7a;
    border: 3px solid #6b64a2;
    outline: initial;
    color: white;

    &_error {
      border-color: $color-red-white;
    }

    &::placeholder {
      text-transform: uppercase;
      color: white;
      opacity: 0.4;
      position: relative;
    }
  }

  &__error-text {
  }
}
</style>
