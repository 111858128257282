import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import PageMain from '../pages/PageMain.vue'
import PageError from '../pages/PageError.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: PageMain,
  },
  {
    path: '/error',
    name: 'error',
    component: PageError,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/error' && from.name === undefined) {
    next({ name: 'main' })
  } else {
    next()
  }
})

export default router
