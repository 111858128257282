import { Module, ActionContext } from 'vuex'
import bowser from 'bowser'

interface DeviceState {
  isLandscape: boolean
  isMobile: boolean
  isConnectionClosed: boolean
  isMute: boolean
}

const state: DeviceState = {
  isLandscape: false,
  isMobile: false,
  isConnectionClosed: false,
  isMute: false,
}

const getters = {
  isLandscape: (state: DeviceState) => state.isLandscape,
  isMobile: (state: DeviceState) => state.isMobile,
}

const mutations = {
  setLandscape(state: DeviceState, isLandscape: boolean) {
    state.isLandscape = isLandscape
  },
  setMobile(state: DeviceState, isMobile: boolean) {
    state.isMobile = isMobile
  },
  setMute(state: DeviceState, isMute: boolean) {
    state.isMute = isMute
    localStorage.setItem('isMute', JSON.stringify(isMute))
  },
  setConnectionClosed(state: DeviceState, isConnectionClosed: boolean) {
    state.isConnectionClosed = isConnectionClosed
  },
}

const actions = {
  detectDevice({ commit }: ActionContext<DeviceState, unknown>) {
    const device = bowser.parse(window.navigator.userAgent)
    const platformType = device.platform.type ?? 'unknown'

    const browserName = device.browser.name?.toLowerCase() ?? 'unknown'
    let browserClass = 'other'
    if (browserName.includes('chrome')) {
      browserClass = 'chrome'
    } else if (browserName.includes('safari')) {
      browserClass = 'safari'
    }

    document.querySelector('html')?.classList.add(`browser-${browserClass}`)

    if (
      process.env.NODE_ENV !== 'production' ||
      process.env.VUE_APP_ALLOW_DESKTOP
    ) {
      commit('setMobile', true)
      return
    }

    commit('setMobile', ['mobile', 'tablet'].includes(platformType))
  },
  checkOrientation({ commit }: ActionContext<DeviceState, unknown>) {
    try {
      const orientation = screen.orientation.type
      const isLandscape = orientation.includes('landscape')
      commit('setLandscape', isLandscape)
    } catch (error) {
      console.error(error)
    }
  },
  init({ dispatch, commit }: ActionContext<DeviceState, unknown>) {
    dispatch('detectDevice')

    const muteState = JSON.parse(localStorage.getItem('isMute') || 'false')

    commit('setMute', muteState)

    if (process.env.VUE_APP_ALLOW_DESKTOP) return

    dispatch('checkOrientation')

    if (screen?.orientation?.type && screen.orientation.addEventListener) {
      screen.orientation.addEventListener('change', () =>
        dispatch('checkOrientation')
      )
    }
  },
}

const device: Module<DeviceState, unknown> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default device
