import axios from 'axios'

export async function preloadAssets() {
  try {
    const { data } = await axios.get('/assets-manifest.json')
    const promises = []

    for (const url of Object.values(data) as string[]) {
      if (url.endsWith('.html')) continue
      if (url.endsWith('.js')) continue
      if (url.endsWith('.woff2')) continue
      if (url.endsWith('.ico')) continue
      if (url.endsWith('bot-qr-code.png')) continue
      if (url.endsWith('.map')) continue

      const img = new Image()

      promises.push(
        new Promise<void>((resolve) => {
          img.onload = () => resolve()
          img.onerror = () => resolve()
          img.onabort = () => resolve()
        })
      )

      img.src = url
    }

    return Promise.all(promises)
  } catch (error) {
    console.error('Error loading assets manifest:', error)
    throw error
  }
}
