<template>
  <div class="app-loader">
    <SpinnerBlock class="box-game__spinner-block" />
  </div>
</template>

<script>
import SpinnerBlock from './SpinnerBlock.vue'
export default {
  components: { SpinnerBlock },
  name: 'AppLoader',
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.app-loader {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
