<template>
  <div v-if="!isLandscape" class="popup-bottom" @click.self="close()">
    <div class="popup-bottom__popup">
      <div v-if="!noClose" class="popup-bottom__close" @click="close()" />

      <div class="popup-bottom__content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PopupBottom',
  props: {
    noClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  computed: {
    ...mapState('device', {
      isLandscape: (state) => state.isLandscape,
    }),
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

// TODO[Стили][Попап] Анимация открытия закрытия
.popup-bottom {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.5);

  &__popup {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 50rem;
    border-radius: 16rem 16rem 0 0;
    background-color: $color-violet-dark;
    max-height: var(--tg-viewport-height);
    overflow-y: scroll;
  }

  &__close {
    position: absolute;
    top: 22rem;
    right: 22rem;
    width: 20rem;
    height: 20rem;
    background: center no-repeat url(@/assets/icons/cross-white.svg);
    background-size: contain;

    &::before {
      --tap-zone: -40rem;
      content: '';
      display: block;
      position: absolute;
      top: var(--tap-zone);
      bottom: var(--tap-zone);
      right: var(--tap-zone);
      left: var(--tap-zone);
    }
  }
}
</style>
