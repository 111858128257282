<template>
  <div class="page-error">
    <div class="page-error__content">
      <h1 class="page-error__title" @click="handleTitleClick">
        {{ $t('errorPage.title') }}
      </h1>
      <div
        class="page-error__errors"
        v-if="!apiMessage && errors.length > 0 && clickCount >= 5"
      >
        <br />
        <div v-for="error in errors" :key="error.id">
          <div>
            <b>{{ error.id }}</b>
          </div>
          <div>{{ error.err }}</div>
          <br />
          <br />
        </div>
      </div>
      <p v-else class="page-error__text">
        {{ apiMessage || $t('errorPage.text') }}
      </p>
      <div class="page-error__button-container">
        <button-action
          class="page-error__button-action"
          size="big"
          :loading="loading"
          @click="reload"
          >{{ $t('errorPage.buttonReloadText') }}</button-action
        >
        <a :href="errorLink" target="_blank">
          <button-action class="page-error__button-action" size="big">{{
            $t('errorPage.buttonSupprotText')
          }}</button-action>
        </a>
      </div>
    </div>
    <span class="page-error__user">userId: {{ userId || '—' }}</span>
  </div>
</template>

<script>
import ButtonAction from '@/components/ButtonAction.vue'

export default {
  components: { ButtonAction },
  name: 'ErrorPage',
  data: () => ({
    loading: false,
    clickCount: 5,
    errorLink: process.env.VUE_APP_SUPPORT_LINK,
    apiMessage: '',
  }),
  computed: {
    userId() {
      return this.$store?.state?.user?.user?.id
    },
    errors() {
      return this.$store.getters['errors/allErrors']
        .map((error) => ({
          ...error,
          err: this.formatError(error.err),
        }))
        .filter((err) => err.err !== '{}')
    },
  },
  methods: {
    reload() {
      this.loading = true
      location.reload()
    },
    handleTitleClick() {
      this.clickCount++
    },
    formatError(error) {
      const authToken = localStorage.getItem('auth_token')

      // if (error?.response?.data?.message) {
      //   this.apiMessage = error?.response?.data?.message
      //   return
      // }

      if (error?.name === 'AxiosError') {
        error = {
          url: error.config.url,
          status: error.response?.status,
          payload: error.config.data,
          response: error.response.data,
        }
      }

      if (authToken && error) {
        return JSON.stringify(error).replace(
          new RegExp(authToken, 'g'),
          '{auth}'
        )
      }

      return JSON.stringify(error)
    },
  },
}
</script>
<style lang="scss">
@import '@/styles/helpers.scss';

.page-error {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15rem;

  &__content {
    padding: 24rem;
    border: 3px solid $color-cyan;
    background-color: rgba($color-violet-dark, 0.95);
    width: 100%;
  }

  &__title {
    font-size: 18rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  &__text {
    font-size: 18rem;
    font-weight: bold;
    text-align: center;
    opacity: 0.4;
  }

  &__errors {
    max-width: 80vw;
    font-size: 12rem;
    max-height: 50vh;
    overflow-y: scroll;
  }

  &__button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15rem;
  }

  &__button-action {
    width: 100%;
  }

  &__user {
    font-size: 16rem;
    padding: 15rem;
    opacity: 0.2;
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>
