import { createI18n } from 'vue-i18n'
import locales from '../locales'

const i18n = createI18n({
  locale: navigator.language,
  fallbackLocale: 'en',
  messages: locales,
  warnHtmlInMessage: 'off',
  pluralizationRules: {
    ru: (choice) => {
      // 3 склонения для примера: 1, 2, 5
      let n = Math.abs(choice)
      n %= 100
      if (n >= 5 && n <= 20) {
        return 2
      }
      n %= 10
      if (n === 1) {
        return 0
      }
      if (n >= 2 && n <= 4) {
        return 1
      }
      return 2
    },
  },
})

export { i18n }

export function t(key: string) {
  return i18n.global.t(key)
}
