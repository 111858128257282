<template>
  <PopupBottom class="popup-previus-balance" @close="closeBalance">
    <div class="popup-previus-balance__content">
      <div class="popup-previus-balance__head">
        {{ $t('popupPreviusBalance.head') }}
      </div>

      <EnergyValue
        type="coins"
        class="popup-previus-balance__coins"
        :value="parseFloat(user.tokens.COINS_OLD)"
        size="huge"
      />

      <div class="popup-previus-balance__button-container">
        <ButtonAction
          class="popup-previus-balance__button"
          size="big"
          color="yellow"
          @click="closeBalance"
        >
          {{ $t('popupPreviusBalance.ok') }}
        </ButtonAction>
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import EnergyValue from '@/components/EnergyValue.vue'
import { mapState } from 'vuex'

export default {
  name: 'PopupPreviusBalance',
  components: { EnergyValue, ButtonAction, PopupBottom },
  emits: ['confirm'],
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    closeBalance() {
      this.$store.commit('user/setShowBalancePopup', false)
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-previus-balance {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__head {
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 16rem;
  }

  &__coins {
  }

  &__text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
    white-space: pre-line;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 32rem;
    width: 100%;
  }

  &__button {
    width: 100%;
  }
}
</style>
