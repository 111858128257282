<template>
  <div class="head-lined">
    <div class="head-lined__line" />
    <div class="head-lined__text">
      <slot />
    </div>
    <div class="head-lined__line" />
  </div>
</template>

<script>
export default {
  name: 'HeadLined',
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.head-lined {
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    padding: 0 8rem;
    font-size: 18rem;
    font-weight: bold;
    line-height: 1.4;
    text-transform: uppercase;
  }

  &__line {
    height: 2px;
    flex: 1;
    background-color: $color-cyan;
  }
}
</style>
