<template>
  <PopupBottom
    class="buy-ad-popup"
    @close="$store.commit('setShowByAdsPopup', false)"
  >
    <MainHeader class="buy-ad-popup__main-header" />
    <div class="buy-ad-popup__content">
      <div class="buy-ad-popup__icon"></div>
      <div class="buy-ad-popup__head">
        {{ $t('popupBuyAd.head') }}
      </div>
      <div
        class="buy-ad-popup__text"
        v-html="$t('popupBuyAd.text', { icon: coinsIconTemplate })"
      ></div>
      <InputBlock
        v-model="inputValue"
        class="buy-ad-popup__input-block"
        :label="$t('popupBuyAd.inputLabel')"
        placeholder="t.me/"
        :errorText="errorTextInput"
        @input="resetError"
      />
      <div class="buy-ad-popup__counter">
        <ButtonAction
          class="buy-ad-popup__button-counter"
          color="pink"
          @click="decCounter"
        >
          -
        </ButtonAction>
        <div class="buy-ad-popup__counter-center">
          <div class="buy-ad-popup__counter-title">
            {{ $t('popupBuyAd.counterHead') }}
          </div>
          <div class="buy-ad-popup__counter-value">{{ adsCounter * 1000 }}</div>
        </div>

        <ButtonAction
          class="buy-ad-popup__button-counter"
          color="green"
          @click="incCounter"
        >
          +
        </ButtonAction>
      </div>
      <div class="buy-ad-popup__button-container">
        <ButtonAction
          class="buy-ad-popup__button"
          size="big"
          color="yellow"
          :loading="loading"
          icon="tether-green"
          @click="byAd"
        >
          {{ $t('popupBuyAd.buttonText', { value: adsCounter * 20 }) }}
        </ButtonAction>
      </div>
      <ErrorText v-if="!!errorTextServer" class="buy-ad-popup__error-text">{{
        errorTextServer
      }}</ErrorText>
      <HeadLined class="buy-ad-popup__head-lined">
        {{ $t('popupBuyAd.rulesTitle') }}
      </HeadLined>
      <div class="buy-ad-popup__rules-list">
        <div
          class="buy-ad-popup__rules-item"
          v-html="$t('popupBuyAd.rulesList.req')"
        />
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import HeadLined from '@/components/HeadLined.vue'
import InputBlock from '@/components/InputBlock.vue'
import ErrorText from './ErrorText.vue'
import MainHeader from '@/components/MainHeader.vue'
import {
  coinsIconTemplate,
  energyIconTemplate,
  peopleIconTemplate,
} from '@/lib/iconTemplates'
import api from '@/api'
import { formatNumber } from '@/lib/utils'

export default {
  name: 'popupBuyAd',
  components: {
    ButtonAction,
    PopupBottom,
    HeadLined,
    InputBlock,
    ErrorText,
    MainHeader,
  },
  emits: ['close', 'squad-created'],
  data: () => ({
    energyIconTemplate,
    coinsIconTemplate,
    peopleIconTemplate,
    inputValue: '',
    errorTextInput: '',
    errorTextServer: '',
    loading: false,
    adsCounter: 1,
  }),
  computed: {
    ...mapState('user', ['user']),
    ...mapGetters('constants', ['constants']),
    formattedLink() {
      let input = this.inputValue.trim()
      input = input.replace(/^(http:\/\/|https:\/\/)?(t\.me\/)?/i, '')

      return `https://t.me/${input}`
    },
  },
  methods: {
    formatNumber,
    ...mapActions('squadsTop', ['fetchActiveTop']),
    decCounter() {
      if (this.adsCounter <= 1) return
      this.adsCounter -= 1
    },
    incCounter() {
      this.adsCounter += 1
    },
    validateInput() {
      // if (Number(this.user.tokens.HYPER) < this.adsCounter * 50000) {
      //   this.errorTextServer = this.$t('popupBuyAd.errors.noCoins')
      //   return false
      // }

      if (!this.inputValue) {
        this.errorTextInput = this.$t('popupBuyAd.errors.invalidUrl')
        return false
      }

      const validStarts = ['t.me', 'http://t.me', 'https://t.me']
      if (
        !validStarts.some((prefix) =>
          this.inputValue.toLowerCase().startsWith(prefix)
        )
      ) {
        if (this.inputValue.includes('/t.me')) {
          this.errorTextInput = this.$t('popupBuyAd.errors.invalidUrl')
          return false
        }
        this.errorTextInput = this.$t('popupBuyAd.errors.invalidDomain')
        return false
      }

      if (this.formattedLink.length < 14) {
        this.errorTextInput = this.$t('popupBuyAd.errors.invalidUrl')
        return false
      }

      return true
    },
    resetError() {
      this.errorTextInput = ''
      this.errorTextServer = ''
    },
    async byAd() {
      if (!this.validateInput()) {
        return
      }

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'clickBuyFor300K',
      })

      this.errorTextServer = this.$t('popupBuyAd.errors.later')
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.buy-ad-popup {
  .popup-bottom__popup {
    position: fixed;
    top: 0rem;
    left: 0;
    width: 100%;
    bottom: 50vh;
    height: var(--tg-viewport-stable-height);
    box-shadow: 0 100vh 0 100vh $color-violet-dark;
    border-radius: 0;
  }

  .popup-bottom__close {
    top: 55rem;
  }

  &__counter-center {
  }

  &__counter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15rem;

    &-title {
      font-size: 12rem;
      text-align: center;
      line-height: 1em;
    }

    &-value {
      font-size: 24rem;
      text-align: center;
      line-height: 1.2em;
    }
  }

  &__button-counter {
    width: 50rem;
    font-size: 40rem;
  }

  &__main-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;

    .main-header__plate_type_invites::before {
      background-color: rgba($color-cyan, 0.2);
    }

    .main-header__plate_type_coins::before {
      background-color: rgba($color-yellow, 0.2);
    }

    .main-header__plate_type_energy::before {
      background-color: rgba($color-green, 0.2);
    }
  }

  &__counter-title {
    text-transform: uppercase;
  }

  &__rules-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15rem;
    margin-bottom: 16rem;
  }

  &__rules-item {
    font-size: 16rem;
    position: relative;
    padding-left: 0.6em;

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0.6em;
      left: 0;
      width: 4rem;
      height: 4rem;
      border-radius: 2rem;
      background: $color-cyan;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    background-image: url(@/assets/icons/ad.svg);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 114rem;
    height: 76rem;
    margin-bottom: 16rem;
    margin: 0 auto 20rem;
  }

  &__head {
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 8rem;
    text-transform: uppercase;
  }

  &__text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: 16rem;
    text-align: center;
  }

  &__head-lined {
    margin-bottom: 16rem;
    margin-top: 16rem;
    width: 100%;
  }

  &__button-container {
    display: flex;
    gap: 8rem;
    width: 100%;
    margin-top: 16rem;
  }

  &__input-block {
    .input-block__label {
      span {
        color: white;
      }
    }
  }

  &__button {
    flex: 1;
  }

  &__error-text {
    width: 100%;
    margin-top: 0.4em;
  }
}
</style>
