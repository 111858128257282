<template>
  <div class="box-game">
    <div v-if="loading" class="box-game__loading">
      <SpinnerBlock class="box-game__spinner-block" />
    </div>

    <div
      :class="bem('box-game__mute', { active: isMute })"
      @click="toggleMute"
    ></div>

    <div class="box-game__close" @click="close()" />

    <BoxGameScene
      class="box-game__screen"
      :key="reloadKey"
      @close="close()"
      @reload="reload()"
    />
  </div>
</template>

<script>
import BoxGameScene from '@/components/BoxGame/BoxGameScene.vue'
import SpinnerBlock from '../SpinnerBlock.vue'
import eventBus from '@/lib/eventBus'
import { mapState } from 'vuex'

export default {
  name: 'BoxGame',
  components: { BoxGameScene, SpinnerBlock },
  emits: ['close'],
  data: () => ({
    gameResults: {
      coins: 0,
    },

    loading: false,

    reloadKey: Math.random(),
  }),
  created() {
    document.querySelector('#app').scrollTo(0, 0)
    document.documentElement.style.overflow = 'hidden'
  },
  unmounted() {
    document.documentElement.style.overflow = ''
  },
  computed: {
    ...mapState('device', ['isMute']),
  },
  methods: {
    toggleMute() {
      eventBus.emit('toggle-mute')
    },
    async close() {
      this.loading = true
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'finishBreakingLootboxes',
      })
      // await this.$store.dispatch('user/fetchUser')
      this.$store.commit('closeBoxGame')
      this.$emit('close')
      // this.loading = false
    },
    reload() {
      this.reloadKey = Math.random()
      this.closeVisible = true
    },
  },
}
</script>

<style lang="scss">
.box-game {
  position: fixed !important;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;

  &__close {
    position: absolute;
    z-index: 20;
    top: calc(var(--header-height) + 30rem);
    right: 30rem;
    width: 20rem;
    height: 20rem;
    background: center no-repeat url(@/assets/icons/cross-white.svg);
    background-size: contain;

    &::before {
      --tap-zone: -40rem;
      content: '';
      display: block;
      position: absolute;
      top: var(--tap-zone);
      bottom: var(--tap-zone);
      right: var(--tap-zone);
      left: var(--tap-zone);
    }
  }

  &__mute {
    width: 30rem;
    height: 30rem;
    position: absolute;
    top: calc(var(--header-height) + 27rem);
    left: 20rem;
    z-index: 20;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(@/assets/icons/sound-on.svg);

    &_active {
      background-image: url(@/assets/icons/sound-off.svg);
    }

    &::before {
      --tap-zone: -40rem;
      content: '';
      display: block;
      position: absolute;
      top: var(--tap-zone);
      bottom: var(--tap-zone);
      right: var(--tap-zone);
      left: var(--tap-zone);
    }
  }

  &__screen {
    position: absolute;
    z-index: 10;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__loading {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__spinner-block {
    font-size: 24rem;
  }
}
</style>
