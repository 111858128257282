import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'

interface AppError {
  id: number
  detail?: string
}

interface ErrorState {
  errors: AppError[]
}

const state: ErrorState = {
  errors: [],
}
const getters: GetterTree<ErrorState, RootState> = {
  allErrors(state): AppError[] {
    return state.errors
  },
}

const mutations: MutationTree<ErrorState> = {
  addError(state, err: AppError) {
    const errorId = Date.now()

    const error = {
      id: errorId,
      err: err,
    }

    state.errors.push(error)
  },
  removeError(state, errorId: number) {
    state.errors = state.errors.filter((error) => error.id !== errorId)
  },
  clearErrors(state) {
    state.errors = []
  },
}

const actions: ActionTree<ErrorState, RootState> = {
  addError({ commit }, error: AppError) {
    commit('addError', error)
  },
  removeError({ commit }, errorId: number) {
    commit('removeError', errorId)
  },
  clearAllErrors({ commit }) {
    commit('clearErrors')
  },
}

const errorModule: Module<ErrorState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default errorModule
