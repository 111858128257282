<template>
  <span class="error-text"> <slot /></span>
</template>

<script>
export default {
  name: 'ErorrText',
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.error-text {
  font-size: 12rem;
  font-weight: 600;
  text-transform: uppercase;
  color: $color-red-white;
  margin-top: 0.2em;
}
</style>
