import * as auth from './endpoints/auth'
import * as users from './endpoints/users'
import * as notifications from './endpoints/notifications'
import * as squads from './endpoints/squads'

export default {
  auth,
  users,
  notifications,
  squads,
}
