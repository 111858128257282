<template>
  <span class="spinner-block" />
</template>

<script>
export default {
  name: 'SpinnerBlock',
}
</script>

<style lang="scss">
.spinner-block {
  width: 1.4em;
  height: 1.4em;
  border: 0.2em solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 0.6s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
