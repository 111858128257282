import { getInstance } from '@/lib/api'

const api = getInstance()

interface TelegramRequest {
  initData: string
}

interface TelegramResponse {
  token: string
}

export function telegram({
  initData,
}: TelegramRequest): Promise<TelegramResponse> {
  return api.post(`auth/telegram`, {
    initData,
  })
}
