<template>
  <span class="countdown-timer">
    <template v-if="days > 0">{{ days }}{{ $t('countdown.d') }} </template>
    {{ hours }}:{{ minutes }}:{{ seconds }}
  </span>
</template>

<script>
export default {
  name: 'CountdownTimer',
  props: {
    targetDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      timeRemaining: 0,
    }
  },
  computed: {
    days() {
      const days = Math.floor(this.timeRemaining / 86400)
      return days
    },
    hours() {
      const hours = Math.floor((this.timeRemaining % 86400) / 3600)
      return String(hours).padStart(2, '0')
    },
    minutes() {
      const minutes = Math.floor((this.timeRemaining % 3600) / 60)
      return String(minutes).padStart(2, '0')
    },
    seconds() {
      const seconds = this.timeRemaining % 60
      return String(seconds).padStart(2, '0')
    },
  },
  methods: {
    updateTime() {
      const now = new Date().getTime()
      const target = new Date(this.targetDate).getTime()
      this.timeRemaining = Math.max(0, Math.floor((target - now) / 1000))

      if (this.timeRemaining === 0) {
        clearInterval(this.timer)
      }
    },
  },
  mounted() {
    this.updateTime()
    this.timer = setInterval(this.updateTime, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
}
</script>
