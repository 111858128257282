import { getInstance } from '@/lib/api'

const api = getInstance()

export function createSquad(link: string): Promise<SquadResponse> {
  return api.post('squads', { link })
}

export function getSquadById(id: string): Promise<void> {
  return api.get(`squads/${id}`)
}

export function joinSquad(id: string): Promise<void> {
  return api.get(`squads/${id}/join`)
}

export function leaveSquad(): Promise<void> {
  return api.get(`squads/leave`)
}

export function collectEnergy(): Promise<void> {
  return api.get(`squads/collect-energy`)
}

export function setFee(fee: number): Promise<void> {
  return api.post(`squads/set-fee`, { fee })
}

export function getDailyTop(): Promise<DailySquadResponse> {
  return api.get(`squads/daily`)
}

export function getWeeklyTop(): Promise<WeeklySquadResponse> {
  return api.get(`squads/weekly`)
}
