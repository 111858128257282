<template>
  <PopupBottom class="popup-invite-terms" no-close @close="$emit('close')">
    <div class="popup-invite-terms__content">
      <div class="popup-invite-terms__head">
        {{ $t('popupInviteTerms.head') }}
      </div>

      <EnergyValue class="popup-invite-terms__energy" :value="0" size="huge" />

      <div class="popup-invite-terms__text">
        {{
          $t('popupInviteTerms.text', {
            userThreshold: 0,
          })
        }}
      </div>

      <div class="popup-invite-terms__button-container">
        <ButtonAction
          class="popup-invite-terms__button"
          size="big"
          color="green"
          @click="$emit('close')"
        >
          {{ $t('popupInviteTerms.ok') }}
        </ButtonAction>
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import EnergyValue from '@/components/EnergyValue.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'PopupInviteTerms',
  components: { EnergyValue, ButtonAction, PopupBottom },
  emits: ['close'],
  computed: {
    ...mapGetters('constants', ['constants']),
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-invite-terms {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__head {
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 16rem;
  }

  &__energy {
    margin-bottom: 32rem;
  }

  &__text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 32rem;
    width: 100%;
  }

  &__button {
    width: 100%;
  }
}
</style>
