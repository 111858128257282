import { GetterTree, Module } from 'vuex'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ConstantsState {}

const state: ConstantsState = {}

const getters: GetterTree<ConstantsState, RootState> = {
  constants(state, getters, rootState): Constants | null {
    return rootState.user?.user?.constants || null
  },
}

const constants: Module<ConstantsState, RootState> = {
  namespaced: true,
  state,
  getters,
}

export default constants
