import { getInstance } from '@/lib/api'

const api = getInstance()

export function me(): Promise<User> {
  return api.get('users/me')
}

export function crashBox(hits: number[]): Promise<CrashBoxResponse> {
  return api.post('/users/crash-energy-box', hits)
} 
export function subscribeChannel(): Promise<User> {
  return api.get('users/subscribe-channel')
}
