import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/main.scss'
import globalMixin from '@/mixins/global'
import { i18n } from './lib/i18n'

const app = createApp(App)

app.config.errorHandler = (err, vm) => {
  console.error(err)

  store.dispatch('errors/addError', err)

  if (vm && vm.$router) {
    vm.$router.push({ name: 'error' })
  }
}

app.mixin(globalMixin).use(store).use(router).use(i18n).mount('#app')
