<template>
  <div class="ad-banner">
    <div class="ad-banner__container">
      <div class="ad-banner__head">
        <div class="ad-banner__info">
          {{ $t('adBanner.info') }}
        </div>
        <div class="ad-banner__close" @click="hideAd"></div>
      </div>
      <div class="ad-banner__window">
        <img class="ad-banner__avatar" src="@/assets/images/ad-avatar.jpg" />
        <div class="ad-banner__title" v-html="title"></div>
        <div class="ad-banner__description" v-html="text"></div>
        <a
          @click="clickAd"
          :href="link"
          target="_blank"
          class="ad-banner__button"
          v-html="buttonText"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['close'],
  created() {
    this.$store.dispatch('eventManager/trackEvent', {
      eventType: 'showAdd',
      eventProperties: { link: this.link },
    })
  },
  computed: {
    title() {
      return this.getEnOrRU(
        process.env.VUE_APP_AD_TITLE_EN,
        process.env.VUE_APP_AD_TITLE_RU
      )
    },
    text() {
      return this.getEnOrRU(
        process.env.VUE_APP_AD_TEXT_EN,
        process.env.VUE_APP_AD_TEXT_RU
      )
    },
    buttonText() {
      return this.getEnOrRU(
        process.env.VUE_APP_AD_BUTTON_TEXT_EN,
        process.env.VUE_APP_AD_BUTTON_TEXT_RU
      )
    },
    link() {
      return this.getEnOrRU(
        process.env.VUE_APP_AD_BUTTON_LINK_EN,
        process.env.VUE_APP_AD_BUTTON_LINK_RU
      )
    },
  },
  methods: {
    getEnOrRU(en, ru) {
      return this.$i18n.locale === 'en-EN' ? en : ru
    },
    clickAd() {
      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'clickPopupAd',
        eventProperties: { link: this.link },
      })
    },
    hideAd() {
      this.$emit('close')

      this.$store.dispatch('eventManager/trackEvent', {
        eventType: 'hideAdd',
        eventProperties: { link: this.link },
      })
    },
  },
}
</script>

<style lang="scss">
.ad-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  &__container {
  }

  &__head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 12rem;
  }

  &__avatar {
    width: 70rem;
    height: 70rem;
    border-radius: 100rem;
    margin: 0 auto 20rem;
  }

  &__info {
    font-size: 12rem;
  }

  &__close {
    width: 20rem;
    height: 20rem;
    background: center no-repeat url(@/assets/icons/cross-white.svg);
    background-size: contain;
  }

  &__window {
    padding: 20rem;
    border-radius: 16rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    width: 330rem;
    min-height: 300rem;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__title {
    font-size: 20rem;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 5rem;
  }

  &__description {
    font-size: 12rem;
    margin-bottom: 16rem;
    text-align: center;
    max-width: 80%;
  }

  &__button {
    padding: 10rem 15rem;
    font-size: 22rem;
    background-color: #2381c9;
    border-radius: 40rem;
    font-weight: 600;
    color: white;
  }
}
</style>
