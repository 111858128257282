import api from '@/api'
import { GetterTree, MutationTree, ActionTree, Module } from 'vuex'

interface SquadsTopState {
  dailyTop: Squad[] | null
  weeklyTop: Squad[] | null
  activeType: 'daily' | 'weekly'
  loading: boolean
}

const state: SquadsTopState = {
  dailyTop: null,
  weeklyTop: null,
  activeType: 'daily',
  loading: false,
}

const getters: GetterTree<SquadsTopState, RootState> = {
  dailyTopItems(state): Squad[] {
    return state.dailyTop || []
  },

  weeklyTopItems(state): Squad[] {
    return state.weeklyTop || []
  },

  activeTopList(state): Squad[] | null {
    if (state.activeType === 'daily') {
      return state.dailyTop
    } else if (state.activeType === 'weekly') {
      return state.weeklyTop
    }
    return null
  },

  getSquadPosition:
    (state, getters) =>
    (squadId: number): number | null => {
      const topList = getters.activeTopList
      if (!topList) return null
      const index = topList.findIndex((squad: Squad) => squad.id === squadId)
      return index !== -1 ? index + 1 : '100+'
    },
}

const mutations: MutationTree<SquadsTopState> = {
  setLoading(state, value: boolean) {
    state.loading = value
  },
  setDailyTop(state, items: Squad[]) {
    state.dailyTop = items
  },
  setWeeklyTop(state, items: Squad[]) {
    state.weeklyTop = items
  },
  setActiveType(state, type: 'daily' | 'weekly') {
    state.activeType = type
  },
}

const fetchMap: { [key: string]: string } = {
  daily: 'fetchDailyTop',
  weekly: 'fetchWeeklyTop',
}

const actions: ActionTree<SquadsTopState, RootState> = {
  async fetchDailyTop({ commit }) {
    commit('setLoading', true)
    const response: DailySquadResponse = await api.squads.getDailyTop()
    commit('setDailyTop', response.items)
    commit('setLoading', false)
  },

  async fetchWeeklyTop({ commit }) {
    commit('setLoading', true)
    const response: WeeklySquadResponse = await api.squads.getWeeklyTop()
    commit('setWeeklyTop', response.items)
    commit('setLoading', false)
  },

  async fetchActiveTop({ state, dispatch }) {
    const fetchAction = fetchMap[state.activeType]
    if (fetchAction) {
      await dispatch(fetchAction)
    }
  },
}

const squadsTop: Module<SquadsTopState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}

export default squadsTop
