<template>
  <div class="box-game-scene-scale">
    <div class="box-game-scene-scale__track-wrapper">
      <div class="box-game-scene-scale__value">
        <div class="box-game-scene-scale__value-percent">
          <div :class="bem('box-game-scene-scale__icon', { type: 'infinity' })">
            💪
          </div>
          <span>∞</span>
        </div>
      </div>
      <div
        :class="
          bem('box-game-scene-scale__track', {
            type: 'main',
          })
        "
      >
        <div
          :class="bem('box-game-scene-scale__track-segment')"
          v-for="({ bonus, players }, index) in levels"
          :key="index"
        >
          <div class="box-game-scene-scale__value">
            <div class="box-game-scene-scale__value-percent">
              <div
                :class="
                  bem('box-game-scene-scale__icon', { type: 'lightning' })
                "
              >
                💪
              </div>
              +{{ bonus }}%
            </div>
            <div class="box-game-scene-scale__value-position">
              <div class="box-game-scene-scale__icon">
                <img src="@/assets/icons/raid.svg" />
              </div>
              {{ formatNumber(players) }}
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          bem('box-game-scene-scale__track', {
            type: 'bonus',
          })
        "
        :style="{
          zIndex: isOnlineMoreThenBonus ? 1 : 0,
        }"
      >
        <div class="box-game-scene-scale__track-segment">
          <div
            :class="
              bem('box-game-scene-scale__track-segment-fill', {
                darkness: !isOnlineMoreThenBonus,
              })
            "
            :style="{
              height: `${getBonusScalePercent}%`,
            }"
          ></div>
        </div>
      </div>
      <div
        :class="
          bem('box-game-scene-scale__track', {
            type: 'players',
          })
        "
        :style="{
          zIndex: isOnlineMoreThenBonus ? 0 : 1,
        }"
      >
        <div class="box-game-scene-scale__track-segment">
          <div
            :class="
              bem('box-game-scene-scale__track-segment-fill', {
                darkness: isOnlineMoreThenBonus,
              })
            "
            :style="{
              height: `${getPlayersScalePercent}%`,
            }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      current: 0.4,
    }
  },
  computed: {
    isOnlineMoreThenBonus() {
      return (
        this.playersOnline < 1 ||
        this.getPlayersScalePercent > this.getBonusScalePercent
      )
    },
    currentBonus() {
      return this.box.bonusDamagePercent
    },
    playersOnline() {
      return this.box.playersOnline
    },
    getBonusScalePercent() {
      return this.getScalePercent(
        this.currentBonus,
        this.levels.map((i) => i.bonus)
      )
    },
    getPlayersScalePercent() {
      return this.getScalePercent(
        this.playersOnline,
        this.levels.map((i) => i.players)
      )
    },
    ...mapState('user', ['box']),
    levels() {
      const levelsArray = Object.keys(this.box.bonusLevels).map((key) => ({
        bonus: this.box.bonusLevels[key],
        players: parseInt(key),
      }))

      return levelsArray.reverse()
    },
  },
  methods: {
    getScalePercent(currentValue, levels) {
      if (currentValue < 0) {
        throw new Error('Value must be 0 or greater')
      }

      const calculateSegment = () => 100 / levels.length

      const mapLevelsToPercentages = (levels) => {
        const segment = calculateSegment()
        return levels
          .sort((a, b) => a - b)
          .map((value, index) => ({
            value: value,
            minPercent: segment * index,
            maxPercent: segment * (index + 1),
          }))
      }

      const findLevel = (value, mappedLevels) => {
        return mappedLevels.find((level) => value <= level.value)
      }

      const calculatePercentage = (value, mappedLevels) => {
        if (value >= mappedLevels[mappedLevels.length - 1].value) {
          return 100
        }

        const level = findLevel(value, mappedLevels)
        const levelIndex = mappedLevels.indexOf(level)
        const previousLevel = mappedLevels[levelIndex - 1] || {
          value: 0,
          maxPercent: 0,
        }

        const percentRange = level.maxPercent - previousLevel.maxPercent
        const valueRange = level.value - previousLevel.value
        const valueDifference = value - previousLevel.value

        return (
          previousLevel.maxPercent +
          (valueDifference / valueRange) * percentRange
        )
      }

      const mappedLevels = mapLevelsToPercentages(levels)
      return calculatePercentage(Number(currentValue), mappedLevels)
    },
    segmentFill(minValue, maxValue, x) {
      let range = maxValue - minValue
      let valueRelativeToRange = x - minValue
      let percentage = (valueRelativeToRange / range) * 100
      return Math.max(Math.min(percentage, 100), 0)
    },
    formatNumber(num) {
      function formatWithSuffix(value, suffix) {
        let formatted = parseFloat(value.toFixed(1))
        return formatted + suffix
      }

      if (num >= 1e9) {
        return formatWithSuffix(num / 1e9, 'B') // миллиарды
      } else if (num >= 1e6) {
        return formatWithSuffix(num / 1e6, 'M') // миллионы
      } else if (num >= 1e3) {
        return formatWithSuffix(num / 1e3, 'k') // тысячи
      }
      return num.toString()
    },
  },
}
</script>

<style lang="scss">
.box-game-scene-scale {
  height: 365rem;
  display: flex;
  font-size: 14rem;
  color: white;
  font-weight: 600;
  padding-left: 78rem;

  &__values {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    padding-right: 5rem;
  }

  &__value {
    padding-right: 5rem;
    position: absolute;
    top: 5rem;
    right: 100%;
    transform: translateY(-50%);
    width: 75rem;
  }

  &__icon {
    width: 23rem;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.1em;

    &_type {
      & _lightning {
        font-size: 18rem;
      }

      &_infinity {
        display: flex;
        font-size: 18rem;
      }
    }
  }

  &__value-percent {
    display: flex;
    align-items: center;

    span {
      font-size: 24rem;
    }
  }

  &__value-position {
    margin-top: -3rem;
    display: flex;
    align-items: center;

    img {
      height: 1em;
    }
  }
  &__track-wrapper {
    height: 100%;
    display: flex;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10rem;
  }

  &__track-segment-fill {
    width: 100%;
    border-radius: 10rem;
    background: #fd7615;
    transition: 0.3s;

    &_darkness {
      background: #9e5b2b;
    }
  }

  &__track {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10rem;
    border-radius: 10rem;
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-direction: column;
    align-items: center;

    &_type {
      &_main {
        position: relative;
        z-index: 10;
        .box-game-scene-scale__track-segment {
          &:after {
            content: '';
            position: absolute;
            display: block;
            width: 4rem;
            height: 4rem;
            background: rgba(255, 255, 255, 0.5);

            border-radius: 10rem;
            transform: translateY(-50%);
            top: 5rem;
          }
        }
      }

      &_players {
        overflow: hidden;
      }
      &_bonus {
        overflow: hidden;
      }
    }
  }

  &__track-segment {
    display: flex;
    align-items: flex-end;
    &:first-child {
      &:after {
        display: none !important;
      }

      .box-game-scene-scale__value {
        display: none;
      }
    }
    position: relative;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
  }

  &__track-dot {
  }

  &__track-line {
    border-radius: 10rem;
    width: 100%;
    position: absolute;
    bottom: 0;
    max-height: 100%;
  }
}
</style>
