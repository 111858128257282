<template>
  <div class="app">
    <AppDevice v-if="shouldShowDevice" class="app__app-device" />

    <AppLoader
      v-if="isMainLoadingShow || (!user && !isError)"
      @after-loading-finished="setMainLoadingShow(false)"
      class="app__app-loader"
    />

    <router-view v-else-if="isMobile" class="app__page" />

    <Teleport to="body">
      <BuyAdPopup v-if="$store.state.showByAdsPopup" />
    </Teleport>

    <SoundEffects />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AppLoader from './components/AppLoader.vue'
import AppDevice from './components/AppDevice.vue'
import WebSocketService from '@/services/WebSocketService'
import SoundEffects from './components/SoundEffects.vue'
import BuyAdPopup from './components/BuyAdPopup.vue'

export default {
  name: 'App',
  components: { AppLoader, AppDevice, SoundEffects, BuyAdPopup },
  computed: {
    ...mapState('user', ['user', 'isCaptchaRequired']),
    ...mapState('device', {
      isLandscape: (state) => state.isLandscape,
      isMobile: (state) => state.isMobile,
      isConnectionClosed: (state) => state.isConnectionClosed,
    }),
    ...mapState({
      isMainLoadingShow: (state) => state.isMainLoadingShow,
      isReconnecting: (state) => state.isReconnecting,
    }),
    shouldShowDevice() {
      return (
        this.isLandscape ||
        !this.isMobile ||
        this.isConnectionClosed ||
        this.isCaptchaRequired ||
        this.isReconnecting
      )
    },
    isError() {
      return this.$route.name === 'error'
    },
  },
  async created() {
    try {
      await this.$store.dispatch('initApp')
      WebSocketService.connect()
    } catch (error) {
      this.$router.push({ name: 'error' })
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/base64-images.scss';
@import '@/styles/helpers.scss';

.app {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;

  &__page {
    position: relative;
    z-index: 20;
    flex-grow: 1;
  }

  &__app-device {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: var(--tg-viewport-stable-height);
    position: fixed;
    z-index: 30;
  }
}
</style>
