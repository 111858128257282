import axios, { AxiosInstance } from 'axios'

let instance: AxiosInstance

export function getInstance(): AxiosInstance {
  if (!instance) {
    instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    })

    instance.interceptors.request.use((request) => {
      const authToken = localStorage.getItem('auth_token')

      request.headers['Authorization'] = `Bearer ${authToken}`

      return request
    })

    instance.interceptors.response.use((response) => response.data)
  }

  return instance
}
