import store from '@/store'
import router from '@/router'

class WebSocketService {
  private socket: WebSocket | null = null
  private reconnectInterval = 5000
  private maxReconnectAttempts = 50
  private reconnectAttempts = 0
  private isReconnecting = false
  private checkBonusInterval: number | null = null

  private getWebSocketUrl(): string {
    return `${process.env.VUE_APP_WS_URL}/users/${
      window?.initDataUnsafe?.user?.id ??
      window.Telegram.WebApp.initDataUnsafe.user.id
    }`
  }

  private initializeWebSocket(url: string): void {
    this.socket = new WebSocket(url)

    this.socket.onopen = () => this.handleOpen()
    this.socket.onmessage = (event) => this.handleMessage(event)
    this.socket.onerror = (error) => this.handleError(error)
    this.socket.onclose = () => this.handleClose()
  }

  connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      const url = this.getWebSocketUrl()
      this.initializeWebSocket(url)
      resolve()
    })
  }

  private handleOpen(): void {
    console.log('Connected to WebSocket server')
    this.resetReconnectAttempts()
    this.sendAuth()
  }

  private resetReconnectAttempts(): void {
    this.reconnectAttempts = 0
    this.isReconnecting = false
    store.commit('setReconnectiong', false)
  }

  private sendAuth(): void {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.sendMessage('auth', {
        initData: window.initData || window.Telegram.WebApp.initData,
        window: {
          width: window.innerWidth,
          height: window.innerHeight,
        },
      })
    }
  }

  private startCheckBonusExpiry(): void {
    if (this.checkBonusInterval) {
      clearInterval(this.checkBonusInterval)
    }

    this.checkBonusInterval = window.setInterval(() => {
      this.checkBonusExpiry()
    }, store.state?.user?.box?.bonusExpireTimeoutRandom * 1000)
  }

  private checkBonusExpiry(): void {
    const bonusExpiresAt = new Date(store.state.user.box.bonusExpiresAt)
    const localTime = new Date()

    if (localTime > bonusExpiresAt) {
      this.sendMessage('state', {})
    }
  }

  private handleMessage(event: MessageEvent): void {
    const message = JSON.parse(event.data)

    if (process.env.NODE_ENV !== 'production') {
      console.log('Received message:', message)
    }

    this.processMessage(message)
  }

  public sendMessage(type: string, payload: any) {
    try {
      this.socket?.send(
        JSON.stringify({
          type,
          payload,
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  private processMessage(message: any): void {
    if (['fail', 'error'].includes(message?.payload?.status)) {
      router.push({ name: 'error' })
      return
    }

    switch (message.type) {
      case 'auth':
        store.commit('user/setUser', { user: message.payload.user })
        store.commit('user/setBox', message.payload.box)
        store.commit('user/setHits', message.payload.hits)

        this.startCheckBonusExpiry()
        break
      case 'state':
        store.commit('user/setUser', { user: message.payload.user })
        store.commit('user/setBox', message.payload.box)
        store.commit('user/setHits', message.payload.hits)

        this.startCheckBonusExpiry()
        break
      case 'hit':
        if (message.payload.status === 'miss') {
          store.dispatch('eventManager/trackEvent', {
            eventType: 'hitLootboxMiss',
          })
          break
        }
        if (message.payload.status === 'captcha_required') {
          store.commit('user/setCaptchaRequired', true)
          break
        }
        store.commit('user/setBox', message.payload.box)
        store.commit('user/setHits', message.payload.hits)
        break
      case 'captcha':
        if (message.payload.status === 'cleared') {
          store.commit('user/setCaptchaRequired', false)

          store.dispatch('eventManager/trackEvent', {
            eventType: 'captchaPassedSuccessfully',
          })
        }
        break
      case 'close':
        if (message.payload.reason === 'superseded_by_new_connection') {
          store.commit('device/setConnectionClosed', true)
        }
        break
    }
  }

  private handleError(error: Event): void {
    console.error('WebSocket error:', error)
    this.reconnect()
  }

  private handleClose(): void {
    console.log('WebSocket connection closed')
    this.reconnect()
    this.clearCheckBonusInterval()
  }

  private clearCheckBonusInterval(): void {
    if (this.checkBonusInterval) {
      clearInterval(this.checkBonusInterval)
      this.checkBonusInterval = null
    }
  }

  private reconnect(): void {
    if (this.canReconnect()) {
      this.reconnectAttempts++
      this.isReconnecting = true
      store.commit('setReconnectiong', true)

      if (this.socket) {
        this.socket.close()
      }

      setTimeout(() => {
        this.connect().finally(() => {
          this.isReconnecting = false
          store.commit('setReconnectiong', false)
        })
      }, this.reconnectInterval)
    } else {
      console.error('Max reconnect attempts reached or disconnected')
    }
  }

  private canReconnect(): boolean {
    return (
      this.reconnectAttempts < this.maxReconnectAttempts &&
      !store.state.device.isConnectionClosed &&
      !this.isReconnecting
    )
  }
}

export default new WebSocketService()
