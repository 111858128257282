export function camelToKebab(string: string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function bem(name: string, mods: object = {}) {
  const result = [name]

  for (const [mod, value] of Object.entries(mods)) {
    if (value === true) {
      result.push(`${name}_${camelToKebab(mod)}`)
    } else if (value !== null && value !== undefined && value !== '') {
      result.push(`${name}_${camelToKebab(mod)}_${camelToKebab(String(value))}`)
    }
  }

  return result.join(' ')
}

export function formatNumber(value: number) {
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}
