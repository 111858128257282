import { MutationTree, ActionTree, Module } from 'vuex'
import api from '@/api'

interface NotificationsState {
  notifications: Notification[]
  receivedNotificationIds: Set<number>
}

const state: NotificationsState = {
  notifications: [],
  receivedNotificationIds: new Set(),
}

const mutations: MutationTree<NotificationsState> = {
  setNotifications(state, notifications: Notification[]) {
    const newNotifications = notifications.filter(
      (notification) => !state.receivedNotificationIds.has(notification.id)
    )

    state.notifications = [...state.notifications, ...newNotifications]
    newNotifications.forEach((notification) =>
      state.receivedNotificationIds.add(notification.id)
    )
  },
  addNotification(state, notification: Notification) {
    if (!state.receivedNotificationIds.has(notification.id)) {
      state.notifications.push(notification)
      state.receivedNotificationIds.add(notification.id)
    }
  },
  removeNotification(state, notificationId: number) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationId
    )
  },
}

const actions: ActionTree<NotificationsState, RootState> = {
  async fetchNotifications({ commit }) {
    const notifications = await api.notifications.getNotifications()
    commit('setNotifications', notifications.items)
  },
}

const notifications: Module<NotificationsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default notifications
