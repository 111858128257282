<template>
  <PopupBottom class="popup-get-reward" no-close>
    <div class="popup-get-reward__content">
      <div class="popup-get-reward__head">
        {{ $t('popupGetReward.head') }}
      </div>

      <EnergyValue
        :type="revardType"
        class="popup-get-reward__energy"
        :value="revardValue"
        size="huge"
      />

      <div class="popup-get-reward__text">
        {{ text }}
      </div>

      <div class="popup-get-reward__button-container">
        <ButtonAction
          class="popup-get-reward__button"
          size="big"
          color="yellow"
          :loading="loading"
          @click="$emit('confirm')"
        >
          {{ $t('popupGetReward.ok') }}
        </ButtonAction>
      </div>
    </div>
  </PopupBottom>
</template>

<script>
import PopupBottom from '@/components/PopupBottom.vue'
import ButtonAction from '@/components/ButtonAction.vue'
import EnergyValue from '@/components/EnergyValue.vue'

export default {
  name: 'PopupGetReward',
  components: { EnergyValue, ButtonAction, PopupBottom },
  emits: ['confirm'],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
    revardValue: {
      type: Number,
      default: 0,
    },
    revardType: {
      type: String,
      default: 'energy',
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/helpers.scss';

.popup-get-reward {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__head {
    font-size: 24rem;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 16rem;
  }

  &__energy {
    margin-bottom: 32rem;
  }

  &__text {
    font-size: 16rem;
    font-weight: 500;
    line-height: 1.4;
    white-space: pre-line;
  }

  &__button-container {
    display: flex;
    justify-content: center;
    margin-top: 32rem;
    width: 100%;
  }

  &__button {
    width: 100%;
  }
}
</style>
