import tap from '@/assets/images/text-tap-en.png'

export default {
  tools: {
    squadLevels: {
      bronze: 'Bronze',
      silver: 'Silver',
      gold: 'Gold',
      diamond: 'Diamond',
    },
    rotateMessage: 'Please rotate the device',
    disconnectedMessage: 'You have started the game on another device',
    playOnMobile: 'Play on your mobile for more fun!',
    countdown: {
      d: 'D',
    },
  },
  popupPreviusBalance: {
    head: 'Previous Game Balance:',
    ok: 'OK',
  },
  pageMain: {},
  gameContent: {
    tabFarm: 'Farm',
    tabTop: 'Rankings',
    tabMySquad: 'My squad',
    yourSquadHead: 'Your squad',
  },
  tasksList: {
    joinChannel: {
      title: 'Join to Hyper to get early access',
      buttonText: 'Join',
    },
    invite: {
      title: 'Invite friends',
      buttonText: 'Invite',
      terms: 'terms',
    },
    joinSquad: {
      title:
        'Join a squad and get <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> every <b>8</b> hours',
      buttonText: 'Squads',
    },
    waitSquadEnergy: {
      title: 'Recharging Energy +{ value } { icon }',
    },
    getSquadEnergy: {
      title: 'Energy recharged',
      buttonText: 'Claim',
    },
    getEnergy: {
      title: 'Get energy every 8&nbsp;hours&nbsp;free',
    },
    byAd: {
      title: 'Buy ad',
      buttonText: 'Buy',
    },
  },
  squadsList: {
    yourSquadHead: 'Your squad',
    listHead: 'Top squads',
    competition: 'Competition',
  },
  squadsEmpty: {
    text: 'No one has created squads yet. Be the first to create one',
    buttonText: 'Create squad',
  },
  squadItem: {
    buttonJoin: 'Join',
    buttonLeave: 'Leave',
    commission: 'fee',
    place: 'rank',
  },
  popupSquad: {
    joinSquad: 'Join squad',
  },
  popupBuyAd: {
    head: 'Buy Advertisement',
    text: '1000 impressions for 300k {icon}',
    counterHead: 'Impressions',
    rulesTitle: 'Rules',
    rulesList: {
      req: 'Advertisements for illegal activities, political ads, and links containing insults will be removed without refund of Hypercoins',
    },
    inputLabel: 'Link to your own(!) Telegram channel/chat',
    buttonText: 'Buy for {value}K',
    errors: {
      invalidUrl: 'Please check the URL',
      invalidDomain: 'Link must start with t.me',
      noCoins: 'Not enough Hypercoins to submit an application',
      later: 'Unable to submit an application, please try again later',
    },
  },
  popupSquadInvite: {
    head: 'Invitation to join the squad',
    join: 'Join',
    decline: 'Decline',
  },
  popupJoinSquad: {
    head: 'You have joined the squad',
    ok: 'Ok',
  },
  popupLeaveSquad: {
    head: 'Do you want to leave the squad?',
    in: 'in',
    text: 'Leaving the squad will reset the energy recovery timer',
    stay: 'Stay',
    leave: 'Leave',
  },
  popupChangeSquad: {
    head: 'Offer to change\nthe squad',
    squadFromHead: 'Your current squad',
    squadToHead: 'New squad',
    buttonChange: 'Change squad',
    buttonDecline: 'Decline',
  },
  popupInviteTerms: {
    head: 'Reward for inviting a friend',
    text: 'You will get a reward when your friend farms {userThreshold} coins',
    ok: 'Ok',
  },
  popupGetReward: {
    head: 'Claim your reward',
    rewards: {
      friends: ({ plural, named }: PluralNamed) =>
        plural([
          `Your reward for inviting ${named('value')} friend`,
          `Your reward for inviting ${named('value')} friends`,
        ]),
      telegramSubscribed:
        'You have successfully subscribed to the Telegram channel',
    },
    ok: 'Claim',
  },
  boxPreview: {
    buttonText: 'Break the box',
    rulesList: {
      players: '{icon} <span>All players deal damage to one loot box</span>',
      damage: '{icon} <span>More players in the raid - more damage</span>',
      coins:
        '{icon} <span>Hypercoins will be distributed proportionally&nbsp;to&nbsp;the damage dealt</span>',
      cashback:
        '{icon} <span><b>{friends}% cashback</b> from invited friends and <b>{friendsFriends}%&nbsp;cashback</b> from friends of your friends</span>',
      ton: '{icon} <span><b>{value} TON</b> will be distributed among the most&nbsp;active 😇 <b>Believers</b></span>',
    },
  },
  boxGameScene: {
    tap: tap,
    buttonInviteText: 'Invite friends',
    buttonCollectReward: 'Collect',
    rewardTitle: 'Hypercoins will be credited within a few days.',
    rewardDescription: 'You will receive a notification from {bot}',
    disclaimer1: '{value}% HYPERCOINS CASHBACK from farming friends',
    disclaimer2: '{value}% HYPERCOINS CASHBACK from friends of your friends',
  },
  noSquadInfo: {
    buttonCreateSquad: 'Create your squad',
    or: 'or',
    joinSquadText:
      'Join a squad and get <span style="white-space: nowrap"><b>{reward}</b> {icon}</span> every <b>8</b> hours!',
  },
  mySquadManager: {
    head: 'Statstics',
    inviteButton: ' Invite to Squad',
  },
  squadStats: {
    income: 'Income',
    members: 'Members squads',
  },
  squadFeeRange: {
    title: 'SQUAD FEE',
    description:
      "The lower the squad's commission,\nthe higher the squad ranks",
  },
  sort: {
    today: 'Today',
    week: 'Week',
  },
  errorPage: {
    title: 'An error has occurred',
    text: 'Unfortunately, an error has occurred. Please try reloading the application or come back later.',
    buttonReloadText: 'Reload',
    buttonSupprotText: 'Write to support',
  },
  adBanner: {
    info: 'Advertisement',
  },
}
