import { getInstance } from '@/lib/api'

const api = getInstance()

export function getNotifications(): Promise<NotificationResponse> {
  return api.get('/notifications')
}

export function deleteNotification(id: string): Promise<void> {
  return api.delete(`/notifications/${id}`)
}
